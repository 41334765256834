//header
let search_btn = $('header .search-btn')
let hamber_btn = $('header .hamber-btn')

search_btn.on('click', function(){
    $('header .search-form').slideToggle()
    hamber_btn.addClass('show')
})

hamber_btn.on('click', function(){
    if($(this).hasClass('show')){
        $('header .header-fullscreen').slideUp()
        $(this).removeClass('show')
    }else{
        $('header .nav-list').slideToggle()
        hamber_btn.addClass('show')
    }
})

$(window).on('scroll', function(){
    if($(document).scrollTop()>50){
        $('header').addClass('header-scroll-down')
        $('header > nav > ul > li > ul').addClass('header-scroll-down')
        $('.header-list').css('opacity', 1)
    }else{
        $('header').removeClass('header-scroll-down')
        $('header > nav > ul > li > ul').removeClass('header-scroll-down')
        $('.header-list').css('opacity', 0)
    }
})

//tab
let tabControl = $('.tab-list li')
let tabContent = $('.tab-content')

tabControl.on('click', function(){
    tabControl.find('a').removeClass('active')

    let thisTabControl = $(this)
    thisTabControl.find('a').addClass('active')

    tabContent.css('display', 'none')

    tabContent.each(function(){
        if($(this).attr('id') == thisTabControl.attr('data-tab')){
            $(this).css('display', 'block')
        }
    })
})

//contact form
let formInput = $('form input')
let formArea = $('form textarea')
let formSubmit = $('form button')

formSubmit.on('click', function(){
    let btn = $(this)

    formInput.each(function(){
        if($(this).attr('required') && $(this).val() == ''){
            btn.attr('disabled', true)
            $(this).parent().append(`<p class="tag required-tip">請輸入內容</p>`)
        }
    })
})

$(window).on('load', function(){
    tabContent.eq(0).css('display', 'block')

    formInput.on('focus', function(){
        formSubmit.attr('disabled', false)
    }).on('blur', function(){
        if($(this).val() !== '')
        $(this).siblings('label').addClass('focus')
    })
})

//footer
$('.gototop-btn').on('click', function(){
    $('html,body').animate({ scrollTop: 0 }, 'slow');
});